import { Injectable } from '@angular/core';
import { DBSchema, openDB, IDBPDatabase } from 'idb';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  private db!: IDBPDatabase<MyDB>;

  constructor() {
    this.connectToDb();
   }

  async connectToDb() {
    this.db = await openDB<MyDB>('custom-db', 1, {
      upgrade(db) {
        db.createObjectStore('login-store', {
          keyPath: 'id',
          autoIncrement: true
        });
      }
    });
  }

  addLogin(data: any) {
    this.connectToDb();
    return this.db.put('login-store', data)
  }

  getLogin() {
    return this.db.getAll('login-store');
  }

  deleteLogin(key: string) {
    return this.db.delete('login-store', key);
  }
}

interface MyDB extends DBSchema {
  'login-store': {
    key: string;
    value: {
      id: number;
      email: string,
      password: string,
      loginDate: Date,
      cleanups: [
        {
          id: string;
          number_of_participants: number;
          plastic_litter_weigth: number;
          other_litter_weight: number;
          date: string;
        }
      ]
    }
  };
}