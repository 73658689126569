import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    constructor(
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authData: string | null = localStorage.getItem(this.authLocalStorageToken);
        if(authData) {
            let data = JSON.parse(authData);
            if(data) {  
                if(data.authToken) {
                    request = request.clone({
                        headers: request.headers
                        .set('Authorization', `Bearer ${data?.authToken}`)
                    });
                }     
            }
        }

        request = request.clone({
            headers: request.headers
                .set('Cache-Control', 'no-cache')
        });


        if(!request.url.includes("upload?entity")) {
            request = request.clone({
                headers: request.headers
                    .set('Content-Type', 'application/json')
                });
        }
        
       
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if(error.status === 401) {
                    console.log ('err status 401');
                    this.router.navigate(['/'])
                    .then(() => {
                        window.location.reload();
                    })
                }
                return throwError(error);
            })
        );
    }
}