<div [@stagger]="true" class="flex-col md:flex-row md:items-start" id="printable">
  <div class="flex flex-col sm:flex-row gap-4">
    <div class="card flex-auto">
      <h1 mat-dialog-title>Offline mode</h1>
      <div mat-dialog-content>
        <p>You are <b>offline</b>. To continue, please log in again.</p>
      </div>
      <div mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
      </div>
    </div>
  </div>
</div>