<div class="footer flex">
  <div class="flex-auto flex items-center container">
    {{ currentYear }} © <a (click)="openLinkInNewTab('https://infordata.it/')" class="link me-2">Infordata</a>
    || <a (click)="openLinkInNewTab('https://www.plasticfreevenice.org/')" class="link ms-2"> VLPF</a>
    <div style="margin-left: auto;">
      <span class="btn btn-sm btn-success p-0 ps-1 pe-1 text-white" *ngIf="isOnline">Online</span>
      <span class="btn btn-sm btn-danger p-0 ps-1 pe-1 text-white" *ngIf="!isOnline">Offline</span>
    </div>
  </div>
</div>
