import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './services/auth-guard/auth-guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing-layout/landing-layout.module').then(m => m.LandingLayoutModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'tips',
    loadChildren: () => import('./pages/tips/tips-carousel/tips-carousel.module').then(m => m.TipsCarouselModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./pages/disclaimer/disclaimer.module').then(m => m.DisclaimerModule)
  },
  {
    path: 'cleanup',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/cleanup/cleanup.module').then(m => m.CleanupModule)
      }
    ]
  },
  {
    path: 'game',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/game/game.module').then(m => m.GameModule)
      }
    ]
  },
  {
    path: 'view-selection',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/view-selection/view-selection.module').then(m => m.ViewSelectionModule)
      }
    ]
  },
  {
    path: 'team',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/team/team.module').then(m => m.TeamModule)
      }
    ]
  },
  {
    path: 'leaderboard',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/leaderboard/leaderboard.module').then(m => m.LeaderboardModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
