import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { TokenInterceptor } from './interceptors/auth-interceptor';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { OfflineModalComponent } from './pages/offline-modal/offline-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlatpickrModule } from 'angularx-flatpickr';

@NgModule({
  declarations: [AppComponent, OfflineModalComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    VexModule,
    CustomLayoutModule,
    MatSidenavModule,
    CommonModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      progressAnimation: 'decreasing',
      preventDuplicates: true,
      progressBar: true,
    }),
    ServiceWorkerModule.register('service-worker.js', {
      enabled: environment.production
    }),
    MatDialogModule,
    FlatpickrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }