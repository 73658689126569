import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() customTemplate: TemplateRef<any>;
  currentYear: number;
  isOnline: boolean;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    this.currentYear = ((new Date()).getFullYear());
  }

  ngOnInit() {
    this.updateOnlineStatus();

    window.addEventListener('online',  this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
  }

  ngOnDestroy(): void {}

  openLinkInNewTab(url: string) {
    window.open(url, "_blank");
  }

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    this.cdr.detectChanges();
  }
}
