<div class="dropdown">
  <div class="dropdown-content flex items-center justify-between" *ngIf="userName || userSurname">
    <div class="flex-auto">
      <div class="notification-label ml-05">{{ userName }} {{ userSurname }}</div>
    </div>
    <div class="dropdown-footer flex items-center justify-between">
      <a (click)="close()" color="primary" mat-button>Logout</a>
    </div>
  </div>
  <div class="dropdown-content items-center justify-between" *ngIf="!userName && !userSurname">
    <div class="flex-auto">
      <div class="notification-label ml-05">{{ userName }} {{ userSurname }}</div>
    </div>
    <div class="dropdown-footer flex items-center justify-between">
      <a (click)="close()" color="primary" mat-button>Logout</a>
    </div>
  </div>
</div>