<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-1 hover:bg-hover"
     matRipple>
  <!-- <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block"></div> -->
  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10">
    <img *ngIf="user?.logo" [src]="logo" alt="Logo" class="w-8 select-none"/>
    <mat-icon *ngIf="!user?.logo" svgIcon="mat:person"></mat-icon>
  </div>
</div>
