import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user/user.service';
import { UserModel } from 'src/app/models/user.model';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  trackById = trackById;
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  id: string;
  user: UserModel;
  userName: string;
  userSurname: string;

  constructor(private cdr: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private auth: AuthService,
              private userService: UserService) {
                this.getLoggedInUser();
              }

  ngOnInit() {
  }

  getLoggedInUser() {
    const authData: string | null = localStorage.getItem(this.authLocalStorageToken);
    if(authData) {
      const data = JSON.parse(authData);
      this.id = data.id;
    }
    if(this.id != undefined) {
      this.userService.view(this.id)
      .subscribe(user => {
        this.user = user;
        if(this.user) {
          this.userName = this.user.name;
          this.userSurname = this.user.surname;
          this.cdr.detectChanges();
        }
      })
    }
  }


  close() {
    this.popoverRef.close();
    this.auth.logout();
  }
}
