import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authService.currentUserValue;
    const lsValue = localStorage.getItem(this.authLocalStorageToken);
    const currentUserToken = JSON.parse(lsValue);
    if (currentUserToken) {
      if (currentUserToken.authToken) {
        return true;
      }
    }
    else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
