import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user/user.service';
import { UserModel } from 'src/app/models/user.model';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  dropdownOpen: boolean;
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  id: string;
  user: UserModel;
  logo: string;

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,
              private userService: UserService,
              private cdr: ChangeDetectorRef) { 
                this.getLoggedInUser();
              }

  ngOnInit() {
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }

  getLoggedInUser() {
    const authData: string | null = localStorage.getItem(this.authLocalStorageToken);
    if(authData) {
      const data = JSON.parse(authData);
      this.id = data.id;
    }
    if(this.id != undefined) {
      this.userService.view(this.id)
      .subscribe(user => {
        this.user = user;
        if(this.user) {
          if(this.user.logo != '') {
            if(this.user.logo != null) {
              this.logo = `${environment.backendUrl}/uploads/user/${this.user.id}/${this.user.logo}`;
              this.cdr.detectChanges();
            }
          }
        }
      })
    }
  }
}
